import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (options.firstInstall) {
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: 'b1cd367b-ab75-47c4-9018-b2e4591f846c',
      installationType: 'closed' as WidgetInstallationType,
      layout: {
        height: 100,
        width: 100,
        x: 0,
        y: 100,
      },
      presetIds: {
        style: 'variants-lgnn37aw',
        layout: 'variants-lgnn37aw',
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 280,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'auto',
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
      },
    });
  }
};
